import { MatDialogRef } from "@angular/material/dialog";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-loyalty-program",
  templateUrl: "./loyalty-program.component.html",
  styleUrls: ["./loyalty-program.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class LoyaltyProgramComponent implements OnInit {
  cvvCode: number|string;
  constructor(private dialogRef: MatDialogRef<LoyaltyProgramComponent>) {}

  ngOnInit(): void {}

  save() {
    this.dialogRef.close();
  }
  close() {
    this.dialogRef.close();
  }
}
