import { HttpClient } from "@angular/common/http";
import { Component, HostListener, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
export function OnIFrameSave(data) {
  console.log(data);
  
}
export function OnIFrameError(error) {
  if (!error) {
    return;
  }
  window.alert(error);
}
@Component({
  selector: "app-hostedpayment-dialog",
  templateUrl: "./hostedpayment-dialog.component.html",
  styleUrls: ["./hostedpayment-dialog.component.scss"],
})
export class HostedpaymentDialogComponent implements OnInit {
  public paymentData: any = {};
  public iFrameUrl;
  constructor(
    public sanitizer: DomSanitizer,
    public http: HttpClient,
    public dialogRef: MatDialogRef<HostedpaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.dialogRef.disableClose = true;
  }
  @HostListener("window:message", ["$event"])
  onMessage(e) {
    if (e.data.event == "cardSaved") {
      // e.data nexio card event
      this.dialogRef.close(e.data.data);
    } else if (e.data.isTestCardEvent == true) {
      // e.data test card event
      this.dialogRef.close(e.data);
    }
  }
  @HostListener("window:OnIFrameSave", ["$event"])
  OnIFrameSave(data) {
  console.log(data);

  }
  @HostListener("window:OnIFrameError", ["$event"])
  OnIFrameError(data) {
  console.log(data);

  }
  ngOnInit(): void {
    this.paymentData = this.data;
    this.setUpIframeMethods();
    this.executePaymentScripts(this.paymentData.Script);
    this.iFrameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.paymentData.IFrameUrl,
    );
  }
  onConfirm(data): void {
  console.log(data);
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
  getIframeHeight(): string {
    return this.paymentData.Height;
  }

  getIframeUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      this.paymentData.IFrameUrl,
    );
  }

  getIframeWidth(): string {
    if(window.innerWidth < 699) {
      return "300";
    }
    return this.paymentData.Width;
  }

  executePaymentScripts(html) {
    // Extract, load and execute <script src> tags
    // Extract and execute <script> tags with inline javascript

    const externalScripts = this.getExternalScripts(html);
    html = this.removeExternalScriptTags(html);

    this.runExternalScripts(externalScripts).then(() => {
      const inlineScripts = this.getInlineScripts(html);
      this.runInlineScripts(inlineScripts);
    });
  }

  getExternalScripts(html) {
    const externalScriptOpenRegex = /<script[^>]*src="[^"]*"[^>]*>/g;
    const srcAttrRegex = /src="([^"]*)"/;

    const scriptTags = html.match(externalScriptOpenRegex);

    if (!scriptTags) {
      return [];
    }

    return scriptTags
      .map((tag) => {
        const link = srcAttrRegex.exec(tag);

        if (!link) {
          return null;
        }

        // Index 1 is the first capture group result
        return link[1];
      })
      .filter((link) => {
        return !!link;
      });
  }

  getInlineScripts(html) {
    const inlineScriptTagRegex = /<script[^>]*>([\s\S]*)<\/script>/g;
    const scriptTags = html.match(inlineScriptTagRegex);

    if (!scriptTags) {
      return [];
    }

    return scriptTags
      .map((tag) => {
        const script = inlineScriptTagRegex.exec(tag);

        if (!script) {
          return null;
        }

        // Index 1 is the first capture group result
        return script[1];
      })
      .filter((script) => {
        return !!script;
      });
  }

  removeExternalScriptTags(html) {
    const externalScriptRegex = /<script[^>]*src="[^"]*"[^>]*>.*<\/script>/g;
    return html.replace(externalScriptRegex, "");
  }

  runExternalScripts(urlArray) {
    const promise = new Promise((resolve) => {
      const firstScript = urlArray.splice(0, 1)[0];
      if (!firstScript) {
        resolve(true);
        return promise;
      }

      this.http.get<any>(firstScript).subscribe(() => {
        this.runExternalScripts(urlArray);
        resolve(true);
      });
    });
    return promise;
  }
  runInlineScripts(scriptArray) {
    window.eval(scriptArray.join("\n;\n"));
  }
  setUpIframeMethods() {
    window["OnIFrameSave"] = OnIFrameSave;
    window["OnIFrameError"] = OnIFrameError;
  }
}
