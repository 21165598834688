import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as _ from "lodash";
import { Cart1Service } from '../../services/cart1.service';
import { SideNavBarService } from '../../services/sidenavbar.service';

@Component({
  selector: 'app-variant-selection',
  templateUrl: './variant-selection.component.html',
  styleUrls: ['./variant-selection.component.scss']
})
export class VariantSelectionComponent implements OnInit {
  product: any;
  productOptions: any = [];
  VariantMapping = {};
  constructor(
    public dialogRef: MatDialogRef<VariantSelectionComponent>,
    private router: Router,
    private cart1Service: Cart1Service,
    private sidenavBarService: SideNavBarService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { 
    const { item } = data;
    if(!item) {
      router.navigate(["/home"]);
    }
    this.product = item || {};
    this.productOptions = item.ItemOptions || [];

    if(this.product) {
      this.setUpOrderOptions(this.product);
    }
  }

  value = [];
  isOptionsSelected: boolean;
  event;
  public orderOptions;


  ngOnInit(): void {
  }

  setUpOrderOptions(item) {
    if (!this.orderOptions) {
      this.orderOptions = {};
    }

    const dict = this.orderOptions;

    // If editing an item with selected customizations, find them and set the dropdowns
    let optionsMap;
    _.each(item.OptionsMap, (val) => {
      val.OptionNames = val.Key.split("|");
    });
    if (item.selectedOptions) {
      item.OptionsMap.some((optMap) => {
        if (optMap.ItemId === (item.KitCustomItemCode || item.ItemID)) {
          optionsMap = optMap.OptionNames;
          return true;
        }
        return false;
      });
    }

    // Otherwise, default to the first options map
    const optionMapNames = item.OptionsMap
      ? item.OptionsMap.map((optMap) => {
          return optMap.OptionNames;
        })
      : [];

    if (!item.selectedOptions || !optionsMap) {
      optionsMap = optionMapNames[2];
    }

    // Loop through the OptionsMap
    let optionVal;
    let optionValues;
    for (const index in optionsMap) {
      if (Object.prototype.hasOwnProperty.call(optionsMap, index)) {
        optionVal = optionsMap[index];
        // For each option in the OptionsMap, loop through the ItemOptions
        for (const itemIndex in item.ItemOptions) {
          // Get all the values of the ItemOption
          if (
            Object.prototype.hasOwnProperty.call(item.ItemOptions, itemIndex)
          ) {
            optionValues =
              item.ItemOptions[itemIndex].Values.map(getOptionValueName);
            // If one of them is the value from the OptionsMap, use it
            const option = item.ItemOptions[itemIndex];
            if (
              !!~optionValues.indexOf(optionVal) &&
              !dict[this.getOrderOptionKey(item, option)]
            ) {
              dict[this.getOrderOptionKey(item, option)] = optionVal;
              break;
            }
          }
        }
      }
    }

    function getOptionValueName(val) {
      return val.Option;
    }
  }

  checkOptions(option) {
    let count = 0;
    this.value.forEach((item) => {
      if (item) {
        count++;
      }
    });

    this.isOptionsSelected = count === option.length;
    console.log("getItemCode", this.getItemCode(this.product));
    
  }

  openSelect(event) {
    this.event = event || this.event;
    // this is due to select input fields are not consistant in some browser
    if (window.screen.availWidth < 600 && !event) {
      setTimeout(() => {}, 100);
    }
  }

  getOrderOptionKey(item, option) {
    return item.ItemID + "__" + option.Option;
  }

  // Determine what values are available based on what's already been selected
  getValidValues(item, option, values) {
    // Get the already-selected option values (except this one and values from other items)
    const currentOption = this.getOrderOptionKey(item, option);
    const otherOptions = [];
    for (const key in this.orderOptions) {
      if (
        key === currentOption ||
        item.ItemID.toString() !== key.split("__")[0]
      ) {
        continue;
      }
      otherOptions.push(this.orderOptions[key]);
    }
    // Whittle down the OptionMaps to the ones that have all the already-selected options
    const optionMapNames = item.OptionsMap
      ? item.OptionsMap.map((optMap) => {
          return optMap.OptionNames;
        })
      : [];
    const validOptionMaps = optionMapNames.filter((optNames) => {
      // For each OptionMap, ensure that it contains all the other selected options
      let optionValue1;
      for (const index in otherOptions) {
        if (Object.prototype.hasOwnProperty.call(otherOptions, index)) {
          optionValue1 = otherOptions[index];
          if (~optNames.indexOf(optionValue1)) {
            continue;
          }
          return false;
        }
      }
      return true;
    });

    // Union the validOptionMaps
    const validOptionValues = validOptionMaps.reduce((arr, optMap) => {
      let optionName;
      for (const index in optMap) {
        if (Object.prototype.hasOwnProperty.call(optMap, index)) {
          optionName = optMap[index];
          if (!~arr.indexOf(optionName)) {
            arr.push(optionName);
          }
        }
      }
      return arr;
    }, []);

    // Set `isAvailable: true` on each option that can be found in validOptionNames, and `isAvailable: false` on the others
    let optionValue;
    for (const index in values) {
      if (Object.prototype.hasOwnProperty.call(values, index)) {
        optionValue = values[index];
        optionValue.isAvailable = !!~validOptionValues.indexOf(
          optionValue.Option,
        );
      }
    }

    // return the original values
    return values.filter((itm) => {
      return itm.isAvailable;
    });
  } 

  setInitialItemOption(option, value) {
    const defaultOption =
      this.orderOptions[this.getOrderOptionKey(this.product, option)] ||
      option.Option;
    if (value.Option === defaultOption) {
      option.selected = value.Option;
    }
  }

  selectOption(item, option, value, isKitItem) {
    option.selected = value.Option;
    this.orderOptions[this.getOrderOptionKey(item, option)] = value.Option;
    const tempOptData = [];
    Object.keys(this.orderOptions).forEach((optdata) => {
      tempOptData.push(optdata);
    });

    if (isKitItem) {
      item.KitCustomItemCode = this.getItemCode(item);
    }
  }

  getItemCode(item) {
    // If there are no customizations, just return the item code
    if (!item.ItemOptions || !item.ItemOptions.length || !item.HasOptions) {
      return this.product.ItemID;
    }

    // If there are customizations, find the right OptionsMap and use its code
    const optionValues = [];
    for (const key in this.orderOptions) {
      if (
        !Object.prototype.hasOwnProperty.call(this.orderOptions, key) ||
        item.ItemID.toString() !== key.split("__")[0]
      ) {
        continue;
      }

      optionValues.push(this.orderOptions[key]);
    }

    optionValues.sort();

    item.selectedOptions = this.getOptionsText(optionValues);

    if (item.HasKitOptions) {
      item.KitGroups.forEach((kitGroup) => {
        kitGroup.Items.forEach(this.getItemCode);
      });
    }

    let mapping;
    for (const index in item.OptionsMap) {
      if (Object.prototype.hasOwnProperty.call(item.OptionsMap, index)) {
        mapping = item.OptionsMap[index];
        mapping.OptionNames.sort();
        if (_.isEqual(optionValues, mapping.OptionNames)) {
          return mapping.ItemId;
        }
      }
    }

    throw new Error(
      "Error: " +
        item.ProductName +
        " does not have a mapping for " +
        optionValues.toString() +
        ".",
    );
  }

  getOptionsText(optionValArray) {
    return "[" + optionValArray.join(", ") + "]";
  }

  addToCart(product) {

    this.cart1Service.addToCart(
      product,
      true,
      this.getItemCode(product),
      false,
      false,
      false,
      false,
    );
    this.dialogRef.close();
    this.sidenavBarService.showsideBarSummary = true;
  }

}
