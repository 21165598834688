import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "../shared/services/auth.guard.service";

import { LoggedInAuthGuard } from "../shared/services/loggedin.guard.service";

import { FlickityComponent } from "./flickity/flickity.component";

import { CustomerManagementComponent } from "./customer-management/customer-management";
import { ContactInfoComponent } from "./ContactInfo/ContactInfo"; 

const routes: Routes = [
  {path:"customermanagement",component:CustomerManagementComponent},
  {path:"contactinfo",component:ContactInfoComponent},
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
