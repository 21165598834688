import { Component, Input } from "@angular/core";
import {
  SwiperConfigInterface,
  SwiperPaginationInterface,
} from "ngx-swiper-wrapper";

@Component({
  selector: "app-main-carousel",
  templateUrl: "./main-carousel.component.html",
  styleUrls: ["./main-carousel.component.scss"],
})
export class MainCarouselComponent {
  @Input("slides") slides = [];
  public config: SwiperConfigInterface = {};
  private pagination: SwiperPaginationInterface = {
    el: ".swiper-pagination",
    clickable: true,
  };
}
