import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productFilterBy',
  pure: false,
})
export class ProductFilterByPipe implements PipeTransform {

  /**
   * @param items - Array
   * @param filter - This is a object with "key" as string and "value" as boolean
   * @returns 
   */
  transform(items, filter): unknown {
    if (!items || !filter || (!filter["best-seller"] && !filter["new"])) {
      return items;
    }

    const filteredItems = [];

    if(filter["best-seller"]) {
      items.forEach(item => {
        (item.Custom?.Field3) &&  filteredItems.push(item);
      });
    }

    if(filter["new"]) {
      items.forEach(item => {
        (item.Custom?.Field4) &&  filteredItems.push(item);
      });
    }

    return filteredItems;
    
  }

}
