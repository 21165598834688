import { SelectionModel } from "@angular/cdk/collections";
import { Component, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { DefaultWebalias, getBaseLocation } from "src/app/baseUrl";
import { UserServiceModal } from "src/app/modals/userservice.modal";
import { ConfigService } from "../../services/config.service";
import { NotificationService } from "../../services/notification.service";
import { RestApiService } from "../../services/restapi.service";
import { UserService } from "../../services/user.service";
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from "../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-find-enroller",
  templateUrl: "./findenroller.component.html",
  styleUrls: ["./findenroller.component.scss"],
})
export class FindEnrollerComponent {
  public isButtonEnable: boolean = false;
  public selection = new SelectionModel<any>(false, []);
  public SelectedSponsor: any = {};
  userService: UserServiceModal;
  searchText: string = "";
  result: string = "";
  itemCount: number = 0;
  currentRouterPath;
  public pageSize: boolean = true;
  public filterData = [];
  showSponsorTable: boolean = false;
  constructor(
    public configService: ConfigService,
    public router: Router,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<FindEnrollerComponent>,
    public apiService: RestApiService,
    public translate: TranslateService,
    public notificationService: NotificationService,
    public user: UserService,
    private route: ActivatedRoute,
  ) {
    this.selection.changed.subscribe(() => {
      this.isButtonEnable = this.selection.selected.length == 0;
    });
    this.userService = user.userServiceModal;
  }
  displayedColumns: string[] = [
    "SelectEnroller",
    "ProfileImageUrl",
    "FirstName",
    // "LastName",
    // "BackOfficeId" /*, "EMail", "Phone" */,
  ];
  dataSource = new MatTableDataSource([]);

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  myControl = new FormControl();
  options: string[] = [];
  filteredOptions: Observable<string[]>;

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value)),
    );
  }

  searchEnroller(searchText: string) {
    const req = {
      includeWebAliases: true,
      searchTerm: searchText,
    };
    this.apiService.searchAssociate(req).subscribe(
      (data) => {
        if (data.Data && data.Data.length) {
          this.filterData = _.filter(data.Data, (data) => {
            this.showSponsorTable = true;
            return (
              (data.AssociateTypeId == 1 && data.WebAliases.length > 0) &&
              !(
                data.FirstName.includes("Solex") ||
                data.FirstName.concat(data.LastName).includes("Solex")
              )
            );
          });
        }
        if (data.Data.length == 0) {
          this.notificationService.error("error_", "Sponsor is not found");
          this.filterData = [];
        }

        this.dataSource = new MatTableDataSource(this.filterData);
        this.dataSource.sort = this.sort;

        this.itemCount = this.filterData.length;
        if (this.itemCount >= 10) {
          this.pageSize = true;
        } else {
          this.pageSize = false;
        }
        this.dataSource.paginator = this.paginator;
      },
      () => {
        // Do stuff whith your error
      },
      () => {},
    );
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue),
    );
  }
  singleSelection($event, dataSource) {
    const numSelected = this.selection.selected.length;
    numSelected <= 0
      ? (this.isButtonEnable = false)
      : (this.isButtonEnable = true);
    if ($event.checked) {
      this.SelectedSponsor = dataSource;
    }
  }
  setSponsor() {
    if (
      this.SelectedSponsor &&
      this.SelectedSponsor.CustomerId &&
      this.SelectedSponsor.WebAliases &&
      this.SelectedSponsor.WebAliases.length > 0
    ) {
      const baseurl = getBaseLocation();
      sessionStorage.setItem("enrollerSet", "true");
      this.route.queryParams.subscribe((params) => {
        if (Object.keys(params).length === 0) {
          location.href = location.pathname.replace(
            baseurl,
            baseurl === "customermanagement"
              ? "customermanagement"
              : `customermanagement`,
          );
        } else {
          this.userService.WebAlias = this.SelectedSponsor.WebAliases[0];
          localStorage.setItem("userService", JSON.stringify(this.userService));
          location.href = location.pathname.replace(
            baseurl,
            baseurl === "customermanagement"
              ? "customermanagement"
              : `customermanagement`,
          );
        }
      });
    } else {
      this.notificationService.error("error_", "webalias_not_exists");
      return false;
    }
  }
  confirmDialog(): void {
    const message = "Are you sure you want to do this?";

    const dialogData = new ConfirmDialogModel("Confirm Action", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      this.result = dialogResult;
    });
  }

  dialogClose() {
    this.dialogRef.close();
  }

  handleNoSponsor() {
    location.href = location.origin + `/${DefaultWebalias}/` + 'home';
  }
}
