import { Component, OnInit, Input } from "@angular/core";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ProductDialogComponent } from "../../products/product-dialog/product-dialog.component";
import { CartService } from "src/app/components/shared/services/cart.service";
import { ProductService } from "src/app/components/shared/services/product.service";
import { ConfigService } from "src/app/components/shared/services/config.service";
import { CompanyService } from "src/app/components/shared/services/company.service";

@Component({
  selector: "app-product-carousel",
  templateUrl: "./product-carousel.component.html",
  styleUrls: ["./product-carousel.component.sass"],
})
export class ProductCarouselComponent implements OnInit {
  @Input("product") product = [];
  public config: SwiperConfigInterface = {};
  contentLoaded = false;
  constructor(
    public configService: ConfigService,
    private dialog: MatDialog,
    private router: Router,
    private cartService: CartService,
    private productService: ProductService,
    public companyService: CompanyService,
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.contentLoaded = true;
    }, 3000);
  }

  public openProductDialog(product) {
    const dialogRef = this.dialog.open(ProductDialogComponent, {
      data: product,
      panelClass: "product-dialog",
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((product) => {
      if (product) {
        this.router.navigate(["/products", product.id, product.name]);
      }
    });
  }

  // Add to cart
  public addToCart(product, quantity: number = 1) {
    this.cartService.addToCart(product, quantity);
  }
}
