
import { Component, Inject, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs/internal/Observable";
import { CompanyService } from "../../shared/services/company.service";
import { NotificationService } from "../../shared/services/notification.service";
import { RestApiService } from "../../shared/services/restapi.service";
import { UserService } from "../../shared/services/user.service";
import { ActivatedRoute } from "@angular/router";
import { trigger, state, style, transition, animate } from '@angular/animations';



@Component({
  selector: "app-ContactInfo",
  templateUrl: "./ContactInfo.html",
  styleUrls: ["./ContactInfo.scss"],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translateX(0)'
      })),
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in')
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})

export class ContactInfoComponent implements OnInit {
  sortBy: any;
  sortDirection: any;
  modelData;
  element:any[];

  constructor(
    private titleService: Title,
    public apiService: RestApiService,
    public translate: TranslateService,
    public notificationService: NotificationService,
    public userService: UserService,
    public companyService: CompanyService,
    public matDialogRef: MatDialogRef<ContactInfoComponent>,
    private _activatedRoute:ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.modelData = data;
    this.getcontactInfo();
    
  }
  closeDialog(): void {
    this.matDialogRef.close();
  }
  // dataSource = new MatTableDataSource(new OrderHistoryResult().Data);
  


  // contact = {
  //   contactID: 3152,
  //   contactType: 'Customer',
  //   displayName: 'Jennifer Gramith',
  //   email: 'jennifer.rightway@gmail.com',
  //   type: 'Regular',
  //   phone1: '2569962364',
  //   phone4: '2569962364',
  //   billingAddress: '7824 Hickory Flat Hwy Ste 140  WOODSTOCK GA 30188',
  //   shippingAddress: '7824 Hickory Flat Highway Suite 140 WOODSTOCK GA 30188'
  // };
  
 
  ngOnInit() {
    this.initOrders();
  }
  ngAfterViewInit() {
    // this.dataSource2.sort = this.sort;
  }
  /**
   * @hidden
   */
  /**
   * Lifecycle hook that is called when any data-bound property of a datasource changes.
   */
  ngOnChanges() {
  }

  initOrders() {
  }
  getcontactInfo() {
    this.apiService.GetContactsInfo(this.modelData.customerDID,this.modelData.RepDID).subscribe((result:any) => {
      try {
        if (result.status == 200) {
          this.element = result.data;
          console.log(this.element)
        } else {
          this.notificationService.error("error_", "NotFound");
        }
      } catch (ex) {
        this.notificationService.error("error_", "error_occured_try_again");
      }
    });
  }
  
}
