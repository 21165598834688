import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CustomerManagementComponent } from "./components/pages/customer-management/customer-management";
import { ContactInfoComponent } from "./components/pages/ContactInfo/ContactInfo";
const appRoutes: Routes = [
  {
    path: "",
    redirectTo: "customermanagement",
    pathMatch: "full",
  },
  {
    path: "",
    redirectTo: "contactinfo",
    pathMatch: "full",
  },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(appRoutes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
