import { Injectable } from "@angular/core";
import { Router, CanActivate, CanActivateChild } from "@angular/router";
import { Observable } from "rxjs";
import { CookieService } from "ngx-cookie-service";
import { UserService } from "./user.service";
@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private cookieService: CookieService,
    public user: UserService,
  ) {}
  canActivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.cookieService.get("X-Auth")) {
      // logged in so return true
      return true;
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(["/login"]).then(() => {
      localStorage.removeItem("user");
      this.cookieService.deleteAll("/");
      sessionStorage.clear();
      localStorage.clear();
      this.user.init();
      window.location.reload();
    });
    return false;
  }

  canActivateChild(): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate();
  }
}
