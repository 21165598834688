import { Injectable } from "@angular/core";
@Injectable({
  providedIn: "root",
})
export class SideNavBarService {
  public showsideBarSummary: boolean = false;
  constructor() {
  }

  public triggerOpen(): void {
    this.showsideBarSummary = !this.showsideBarSummary;
  }

  public closeSidePanel(): void {
    this.showsideBarSummary = false;
  }
}
