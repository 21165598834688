import {
  AfterViewInit,
  Component,
  OnChanges,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs/internal/Observable";
import { map, startWith } from "rxjs/operators";
import { OrderHistory, OrderHistoryResult } from "src/app/modals/orderhistory.modal";
import { CompanyService } from "../../shared/services/company.service";
import { NotificationService } from "../../shared/services/notification.service";
import { RestApiService } from "../../shared/services/restapi.service";
import { UserService } from "../../shared/services/user.service";
import { ActivatedRoute } from "@angular/router";
import { ContactInfoComponent } from "../ContactInfo/ContactInfo";


@Component({
  selector: "app-customer-management",
  templateUrl: "./customer-management.html",
  styleUrls: ["./customer-management.scss"],
})

export class CustomerManagementComponent implements OnInit, AfterViewInit, OnChanges {
  sortBy: any;
  sortDirection: any;

  constructor(
    private titleService: Title,
    public dialog: MatDialog,
    public apiService: RestApiService,
    public translate: TranslateService,
    public notificationService: NotificationService,
    public userService: UserService,
    public companyService: CompanyService,
    private _activatedRoute:ActivatedRoute,
  ) {
    const params = { ...this._activatedRoute.snapshot.queryParams };
    var key = params.sso;
    this.guid = key;
    this.level = 1
  }
  @ViewChild(MatSort) sort: MatSort;
  
  displayedColumns: string[] = [
    "customerDID",
    // "Email",
    "customerName",
    "level",
    "currentMonthOrder",
    "activeAutoship",
    "joinDate", 
    "lastOrder"
  ];
  
 
  // dataSource = new MatTableDataSource(new OrderHistoryResult().Data);
  dataSource2 = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    this.dataSource2.paginator = value;
  }

  
  myControl = new FormControl();
  options: string[] = [];
  filteredOptions: Observable<string[]>;
  itemCount: number = 0;
  List:any[]=[]
  guid:any;
  level:any;
  searchText:FormControl = new FormControl('');
  selectLevel:FormControl = new FormControl(1);
  onSelectionChange(element: any) {
    console.log('Selection changed: ', element);
  }
  ngOnInit() {
    // this.translate.get("global_Company_Title").subscribe((text: string) => {
    //   this.titleService.setTitle(
    //     this.translate.instant("global_Company_Title") + " | " + text,
    //   );
    // });

     this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value)),
    );
    this.applyLevelFilter({ value: 1 });
    this.dataSource2.sort = this.sort;
    

    this.initOrders();
    this.searchText.valueChanges.pipe().subscribe((value)=>{
      if(value.length>1){
        this.search(value.toLowerCase());
      }
      else{
        this.dataSource2 = new MatTableDataSource(this.List)
      }
    })
    this.searchText.valueChanges.pipe().subscribe((value)=>{
      if(value.length>1){
        this.search(value.toLowerCase());
      }
      else{
        this.dataSource2 = new MatTableDataSource(this.List)
      }
    })
  }
  ngAfterViewInit() {
    // this.dataSource2.sort = this.sort;
    this.dataSource2.paginator = this.paginator;
  }
  /**
   * @hidden
   */
  /**
   * Lifecycle hook that is called when any data-bound property of a datasource changes.
   */
  ngOnChanges() {
    
    // this.getCustomerDownline();
  }

  initOrders() {
    
    // this.getCustomerDownline();
  }
  applyFilter(filterValue: string) {
    this.dataSource2.filter = filterValue.trim().toLowerCase();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue),
    );
  }
  ViewContactInfo(customerDID,RepDID): void {
    const DataId = {customerDID,RepDID};
    this.dialog.open(ContactInfoComponent, {
      width: "50vw",
      maxWidth: "50vw",
      panelClass: "invoiceDialog",
      data: DataId,
      autoFocus: false,
    });
  }
  getCustomerDownline(){
    
    this.apiService.GetContacts(this.guid,this.level).subscribe(
      (res:any ) => {
        if(res.status == 200){
          if (res.data === null || res.data === 'null')
           {
            this.List = [];
          }
          else{
            this.List = res.data
          }
          this.dataSource2 = new MatTableDataSource(this.List);
          this.dataSource2.sort = this.sort;
          this.dataSource2.paginator = this.paginator;
        }
        else{
          this.List = []
          this.dataSource2 = new MatTableDataSource(this.List);
          this.dataSource2.sort = this.sort;
          this.dataSource2.paginator = this.paginator;
        }
      },
      (err) => {
        this.List = [];
        this.dataSource2 = new MatTableDataSource(this.List);
        this.dataSource2.sort = this.sort;
        this.dataSource2.paginator = this.paginator;
      },
    );
  }
  search(text){
    console.log(text)
    var tempList =  this.List.filter(x=>((x.customerName != null && x.customerName != '' && x.customerName.toLowerCase().includes(text)) || x.customerDID.toLowerCase().includes(text) || x.joinDate.toLowerCase().includes(text)));
    this.dataSource2 = new MatTableDataSource(tempList)
    
  }
  
  applyLevelFilter(ev): void {
    const selectedLevel = ev.value;
    this.level = ev.value
    console.log(ev)
    this.getCustomerDownline();
    if (selectedLevel ==  0) {
      this.dataSource2 = new MatTableDataSource(this.List)
      
    } else {
     var List = this.List.filter(element => element.level == selectedLevel);
      this.dataSource2 = new MatTableDataSource(List);
      
    }
    
  }
  sortData(event){
    this.sortBy = event.active;
    this.sortDirection = event.direction;
    this.performSort();
  }

  performSort() {
    if (!this.sortBy || !this.sortDirection) {
      return;
    }

    this.dataSource2.data = this.List.sort((a, b) => {
      let compare = 0;

      if (a[this.sortBy] > b[this.sortBy]) {
        compare = 1;
      } else if (a[this.sortBy] < b[this.sortBy]) {
        compare = -1;
      }
      return this.sortDirection === 'asc' ? compare : -compare;
    });
  }
  
}
