import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SidebarMenuService {
  public appDrawer;
  public currentUrl = new BehaviorSubject<string>(undefined);

  constructor(private router: Router) {}
}
